import * as React from "react"
import { 
  graphql, 
  // navigate 
} from "gatsby"

import Layout from "../../components/layout"
// import {useTranslation} from 'gatsby-plugin-react-i18next';
import { Typography } from '@material-ui/core';


import { connect } from 'react-redux';
// import * as uiActions from '../../redux/actions/uiActions';
// import * as systemActions from '../../redux/actions/systemActions';
// import * as sessionActions from '../../redux/actions/sessionActions';
// import Api from '../../lib/api';
// import Helper from '../../lib/helper';

const TemplatePage = () => {
  // const {t} = useTranslation('translation');
  
  // React.useEffect(() => {
    
  // }, []);

  return (
    <Layout>
      <Typography variant='h5'>
        Template Page
      </Typography>
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(TemplatePage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
